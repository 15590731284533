<template>
  <div class="red--text">
    <h2>Error Logging In</h2>
    <h3>Please make sure you are using the correct login credentials and have access to the site.</h3>
  </div>
</template>

<script>
export default {
  name: 'unauthorized'
};
</script>
